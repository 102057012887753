export class LoggedInUserInfo {
    userId: string = '';
    email: string = '';
    firstName: string = '';
    lastName: string = '';  
    languageId: string | null = null;
    timeZoneId: string = '';
    profileImageUrl: string | null = null;
    roleName: string = '';
    roleId: string = '';
    phoneNumber: string = '';
    timezoneOffset: string = '';
    timezoneName: string = '';
}