import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map } from 'rxjs';
import { BaseService } from 'src/app/shared/services/base.service';
import { environment } from 'src/environments/environment.development';
import { InviteUserModel } from './models/invite-user.model';
import { ResponseResult } from 'src/app/shared/models/response';
import { InviteeInfo } from './models/invitee-infro.model';
import { UserSummary } from './models/user-summary.model';
import { UserListFilter } from './models/user-list-filter.model';
import { LoggedInUserInfo } from 'src/app/models/user-account/logged-in-user-model';
import { StateObserverService } from 'src/app/shared/services/state-observer.service';
import { IsEmailUsedCheck } from './models/is-emai-used-check';
import { IsEmailUsedResponse } from './models/is-email-used-response';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService {

  adminAPIUrl = environment.adminResourceUrl;
  loggedInUserInfo = new LoggedInUserInfo();

  constructor(private http: HttpClient, private stateObserverService: StateObserverService) {
    super();
  }

  inviteMember(user: InviteUserModel): Observable<void> {
    return this.http.post<void>(environment.adminResourceUrl + "api/setup/users/invite", user, this.httpOptions)
      .pipe(catchError(this.handleServerError))
  }

  getInviteeInfo(tenantId: string, email: string): Observable<ResponseResult<InviteeInfo>> {

    let query = `tenantId=${tenantId}&email=${email}`;

    let url = `${this.adminAPIUrl}api/tenant-user/invitee-details?${query}`;

    return this.http.get<ResponseResult<any>>(url)
      .pipe(map(response => response), catchError(this.handleServerError));
  }

  isTenantUserEmailAlreadyTaken(data: IsEmailUsedCheck): Observable<ResponseResult<IsEmailUsedResponse>> {
    return this.http.post<ResponseResult<IsEmailUsedResponse>>(`${this.adminAPIUrl}api/tenant-user/check-email-availability`, data, this.httpOptions)
      .pipe(map(resp => resp), catchError(this.handleServerError));
  }

  deleteUser(userId: string): Observable<void> {
    return this.http.delete<void>(`${this.adminAPIUrl}api/tenant-user/${userId}`, this.httpOptions)
      .pipe(catchError(this.handleServerError));
  }

  getUserList(filter: UserListFilter): Observable<ResponseResult<UserSummary[]>> {
    let query = `PageSize=${filter.paginator.pageSize}&PageNumber=${filter.paginator.pageNumber}&SearchValue=${filter.searchValue}`;

    let url = `${this.adminAPIUrl}api/setup/users?${query}`;

    return this.http.get<ResponseResult<UserSummary[]>>(url)
      .pipe(map(resp => resp), catchError(this.handleServerError));
  }

  getLoggedInUserInfo(): Observable<ResponseResult<LoggedInUserInfo>> {

    let url = `${this.adminAPIUrl}api/current-user/logged-in-user-info`;

    return this.http.get<ResponseResult<LoggedInUserInfo>>(url)
      .pipe(map(response => {
        this.loggedInUserInfo = response.data;
        this.stateObserverService.updateLoggedInUser(this.loggedInUserInfo);
        return response;
      }
      ), catchError(this.handleServerError));
  }

  resendInvitation(userId: string): Observable<void> {
    return this.http.post<void>(environment.adminResourceUrl + `api/setup/users/${userId}/resend-invite-email`, this.httpOptions)
      .pipe(catchError(this.handleServerError));
  }
}
